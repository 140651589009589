import api from 'services/api';
import {
  Sortear,
  SortearResponse,
  SortearResponseError,
  GetPremios,
  GetPremiosResponse,
  GetSorteio,
  GetSorteioResponse,
  GetSaldoUsuario,
  GetSaldoUsuarioResponse,
  GetResultadoSorteio,
  GetResultadoSorteioResponse,
  ListarCreditosDetalhados,
  ListarCreditosDetalhadosResponse,
  ListarSaldoLiberado,
  ListarSaldoLiberadoResponse,
  ListarSaldoBloqueado,
  ListarSaldoBloqueadoResponse,
  ListarExtratoPagamento,
  ListarExtratoPagamentoResponse,
} from './types';
//#region Sortear

/**Debita um credito e busca o resultado do sorteio */
export const sortear: Sortear = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<SortearResponse, SortearResponseError>(
      'sorteio/sortear',
    );
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    reject(response.data);
  });
};

//#endregion

//#region GetPremios

//**Método que lista os prêmios sorteados por ultimo */
export const getPremio: GetPremios = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<GetPremiosResponse>(
      `sorteio/obterUltimosPremios`,
    );
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};

//#endregion

//#region Buscar sorteio
/** Retorna os dados do sorteio vigente e os creditos do usuario */
export const getSorteio: GetSorteio = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<GetSorteioResponse>(
      'sorteio/obtersorteiovigente',
    );
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    reject(response.data);
  });
};
//#endregion

//#region ObterSaldoUsuario

export const getSaldoUsuario: GetSaldoUsuario = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<GetSaldoUsuarioResponse>(
      'sorteio/obterSaldo',
    );
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};
//#endregion

//#region GetResultadoSorteio

export const getResultadoSorteio: GetResultadoSorteio = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<GetResultadoSorteioResponse>(
      'sorteio/obterPremioTotal',
    );
    if (response.data && response.ok) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};

//#endregion

/**Lista todos os creditos do usuario detalhadamente*/
export const listarCreditosDetalhados: ListarCreditosDetalhados = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<ListarCreditosDetalhadosResponse>(
      'credito/obterCreditos',
    );
    if (response.data && response.ok) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};

/**Listar o saldo das premiações que esta liberado*/
export const listarSaldoLiberado: ListarSaldoLiberado = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<ListarSaldoLiberadoResponse>(
      'sorteio/obterCreditosLiberado',
    );
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};

/**Listar o saldo das premiações que esta bloqueado */
export const listarSaldoBloqueado: ListarSaldoBloqueado = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<ListarSaldoBloqueadoResponse>(
      'sorteio/obterCreditosBloqueado',
    );
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};

/**Lista o extrato de pagamento das premiações */
export const listarExtratoPagamento: ListarExtratoPagamento = () => {
  return new Promise(async (resolve, reject) => {
    const response = await api.get<ListarExtratoPagamentoResponse>(
      'sorteio/obterExtratoPagamento',
    );
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};
