import React, {useContext, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';
import {AuthContext} from 'context/AuthContext';

import background from 'assets/images/backgroundLogin.png';

import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import PlayingCard from 'components/PlayingCard';

import {BiUser} from 'react-icons/bi';

import {
  Row,
  Group,
  Container,
  FormHeader,
  FormMiddle,
  FormFooter,
  FormContainer,
} from './styles';

const ForgotPassword: React.FC = () => {
  const {isMobile} = useTheme();
  const [username, setUserName] = useState('');

  const {actions: authActions} = useContext(AuthContext);
  const {colors, fonts} = useTheme();
  const history = useHistory();

  const forgotPassword = async () => {
    const result = await authActions.forgotPassword(username);
    if (result.ok) {
      history.push({
        pathname: '/resetPassword',
        state: {username: username},
      });
    } else if (!result.ok) {
      alert(result.message);
    }
  };

  return (
    <Container style={customContainerStyle}>
      {!isMobile && (
        <Group>
          <PlayingCard time={5}></PlayingCard>
        </Group>
      )}
      <Group backgroundColor={colors.primary}>
        <FormContainer>
          <FormHeader>
            <Text size={20} font={fonts.poppins_light}>
              Esqueceu sua{' '}
              <Text size={20} color={colors.yellow} font={fonts.poppins_medium}>
                &nbsp; SENHA?
              </Text>
            </Text>
          </FormHeader>
          <FormMiddle>
            <Input
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Email"
              icon={() => <BiUser />}
            />
            <Text size={15} font={fonts.poppins_light}>
              Enviaremos um e-mail com um código de recuperação de senha
            </Text>
          </FormMiddle>
          <FormFooter>
            <Row>
              <Button onClick={forgotPassword}>ENVIAR</Button>
            </Row>
          </FormFooter>
        </FormContainer>
      </Group>
    </Container>
  );
};

const customContainerStyle = {
  backgroundImage: `url(${background})`,
};

export default React.memo(ForgotPassword);
