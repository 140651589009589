import React from 'react';

import {HeaderModalProps} from './types';
import {useTheme} from 'styled-components';
import {AiOutlineCloseCircle} from 'react-icons/ai';

import {Title, HeaderItem, HeaderContainer} from './styles';

const Header: React.FC<HeaderModalProps> = ({onClose}) => {
  const {colors} = useTheme();

  return (
    <HeaderContainer>
      <HeaderItem />
      <Title>COMO JOGAR</Title>
      <HeaderItem onClick={onClose}>
        <AiOutlineCloseCircle color={colors.secundary} size={30} />
      </HeaderItem>
    </HeaderContainer>
  );
};

export default React.memo(Header);
