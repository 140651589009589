import React from 'react';

import {useTheme} from 'styled-components';
import type {} from '@mui/x-data-grid/themeAugmentation';
import {createTheme, ThemeProvider} from '@mui/material';
import {ptBR} from '@mui/x-data-grid';
import {ptBR as corePtBR} from '@mui/material/locale';

const MaterialUIProvider: React.FC = ({children}) => {
  const {colors, fonts} = useTheme();
  const theme = createTheme(
    {
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: {
              backgroundColor: colors.primary,
              borderWidth: 0.1,
              color: colors.primary_text,
              fontFamily: fonts.poppins_medium,
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              color: colors.primary_text,
              fontFamily: fonts.poppins_medium,
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              color: colors.primary_text,
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {color: colors.primary_text},
          },
        },
      },
    },
    ptBR,
    corePtBR,
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialUIProvider;
