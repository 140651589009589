import api from '../api';

import {
  Authorize,
  AuthorizeResponse,
  ForgotPassword,
  ForgotPasswordResponse,
  ResetPassword,
  ResetPasswordResponse,
} from './types';

//#region Auth

/**Método que garante acesso ao sistema */
export const authorize: Authorize = (obj) => {
  return new Promise(async (resolve, reject) => {
    const {grantType, password, userName} = obj;

    const response = await api.post<AuthorizeResponse>('auth/token', {
      UserName: userName,
      Password: password,
      GrantType: grantType,
    });
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    const {data = {}}: any = response;
    let message =
      data?.message || 'Tivemos um problema, tente novamente mais tarde';
    if (response.status === 401 && !data?.message) {
      message = 'Senha ou usuário incorretos';
    }
    return reject({message, ...response.data});
  });
};

/**Método esqueci minha senha */
export const forgotPassword: ForgotPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    const userName = data;
    const response = await api.post<ForgotPasswordResponse>(
      'Auth/forgotPassword',
      {
        username: userName,
      },
    );
    if (response.ok) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};

/** Método de resetar a senha */
export const resetPassword: ResetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    const {userName, password, resetPasswordCode} = data;
    const response = await api.post<ResetPasswordResponse>(
      'auth/resetPassword',
      {
        username: userName,
        password: password,
        resetpasswordcode: resetPasswordCode,
      },
    );
    if (response.ok && response.data) {
      return resolve(response.data);
    }
    return reject(response.data);
  });
};

//#endregion
