//#region State

import {DefaultResponse} from 'models';

export enum AuthTypes {
  SignIn = 'SIGN_IN',
  SignOut = 'SIGN_OUT',
  RestoreToken = 'RESTORE_TOKEN',
}

interface SignInReducer {
  type: typeof AuthTypes.SignIn;
  token: string;
}
interface SignOutReducer {
  type: typeof AuthTypes.SignOut;
}

interface RestoreTokenReducer {
  type: typeof AuthTypes.RestoreToken;
  token: string | null | undefined;
}

export type AuthAction = SignInReducer | SignOutReducer | RestoreTokenReducer;

export interface ContextState {
  token: string | null | undefined;
}

export type Reducer = (
  prevState: ContextState,
  action: AuthAction,
) => ContextState;

//#endregion

//#region Actions

//SignIn
type SignIn = (userName: string, password: string) => Promise<DefaultResponse>;

//SignOut
type SignOut = () => void;

//ForgotPassword
type ForgotPassword = (userName: string) => Promise<DefaultResponse>;

//ResetPassword
type ResetPassword = (
  userName: string,
  password: string,
  resetpasswordcode: string,
) => Promise<DefaultResponse>;

export interface ContextActions {
  signIn: SignIn;
  signOut: SignOut;
  forgotPassword: ForgotPassword;
  resetPassword: ResetPassword;
}
//#endregion

//Context
export interface Context {
  state: ContextState;
  actions: ContextActions;
}
