import styled from 'styled-components';

import Text from 'components/Text';

const EXTREMITY_HEIGHT = 15;

export const Container = styled.div`
  width: 100%;
  height: ${EXTREMITY_HEIGHT}%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PlayButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 25vw;
  padding: 10px;
  height: 50px;
  margin: 20px 5px 0px;
  background: ${({theme}) => theme.colors.yellow};
`;
export const PlayButtonText = styled(Text)`
  font-size: 12px;
  font-family: ${({theme}) => theme.fonts.poppins_medium};
  color: ${({theme}) => theme.colors.secundary};
`;
