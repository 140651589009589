import React, { useState } from 'react';

import { PlayingCardProps } from './types';

import { Card, Face, FaceBack, CardImage } from './styles';

import frontImage from 'assets/images/gerenciarCard_front.png';
import backImage from 'assets/images/gerenciarCard_back.png';

const PlayingCard: React.FC<PlayingCardProps> = ({ time = 2 }) => {
  const [angle, setAngle] = useState(180);

  const flipCard = () => {
    if (angle === 360) {
      setAngle(angle - 180);
      return;
    }
    setAngle(angle + 180);
  };

  return (
    <Card onClick={flipCard} angle={angle} time={time}>
      <Face>
        <CardImage src={frontImage} />
      </Face>
      <FaceBack>
        <CardImage src={backImage} />
      </FaceBack>
    </Card>
  );
};

export default React.memo(PlayingCard);
