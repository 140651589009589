import React, {useImperativeHandle, forwardRef, useState} from 'react';

import {HowToPlayProps, HowToPlayMethods} from './types';

import {Modal} from '@material-ui/core';
import Header from './components/Header';
import Middle from './components/Middle';
import Footer from './components/Footer';

import {Container, InnerContainer} from './styles';

const HowToPlay = forwardRef<HowToPlayMethods, HowToPlayProps>(
  ({closeModal, ...rest}, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => {
      setIsOpen(true);
    };
    const close = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open,
      close,
    }));

    return (
      <Modal {...rest} open={isOpen}>
        <Container>
          <InnerContainer>
            <Header onClose={() => closeModal(false)} />
            <Middle />
            <Footer onPlay={() => closeModal(true)} />
          </InnerContainer>
        </Container>
      </Modal>
    );
  },
);
export * from './types';
export default React.memo(HowToPlay);
