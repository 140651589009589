import React, {useContext} from 'react';

import {useTheme} from 'styled-components';
import {SorteioContext} from 'context/SorteioContext';

import coin from 'assets/images/coinStar.png';

import Text from 'components/Text';
import {Container, CoinIcon, InnerContainer} from './styles';

const Credito: React.FC = () => {
  const {fonts} = useTheme();
  const {
    state: {creditos},
  } = useContext(SorteioContext);
  return (
    <Container>
      <InnerContainer>
        <CoinIcon src={coin} />
        <Text font={fonts.poppins_semiBold} size={30}>
          {creditos}
        </Text>
      </InnerContainer>
    </Container>
  );
};

export default React.memo(Credito);
