import {Fonts} from 'models';

const fonts: Fonts = {
  poppins_light: 'Poppins-Light',
  poppins_medium: 'Poppins-Medium',
  poppins_semiBold: 'Poppins-SemiBold',
  poppins_regular: 'Poppins-Regular',
};

export default fonts;
