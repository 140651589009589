import React, {useMemo, createContext, useReducer, useEffect} from 'react';

import {User} from 'models';
import {Context, Reducer, UserTypes, ContextActions} from './types';

export const UserContext = createContext<Context>({} as Context);

const UserProvider: React.FC = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions: ContextActions = useMemo(
    () => ({
      setUser: (data) => {
        dispatch({type: UserTypes.SetUser, user: data});
      },
    }),
    [],
  );

  useEffect(() => {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      actions.setUser(currentUser);
    }
  }, [actions]);

  return (
    <UserContext.Provider
      value={{
        actions,
        state,
      }}>
      {children}
    </UserContext.Provider>
  );
};
const initialState = {
  user: {} as User,
};

const reducer: Reducer = (prevState, action) => {
  switch (action.type) {
    case UserTypes.SetUser:
      return {...prevState, user: action.user};
    default:
      return {...prevState};
  }
};
export default UserProvider;
