import styled from 'styled-components';

import Text from 'components/Text';
import {Link} from 'react-router-dom';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

export const Title = styled(Text)`
  font-size: 24px;
  text-align: center;
  font-family: ${({theme}) => theme.fonts.poppins_semiBold};
`;

export const BackButtonContainer = styled(Link)`
  margin: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  color: ${({theme}) => theme.colors.primary_text};
  font-family: ${({theme}) => theme.fonts.poppins_medium};
`;
