import styled from 'styled-components';

import _Text from 'components/Text';

const EXTREMITY_HEIGHT = 15;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: ${EXTREMITY_HEIGHT}vh;
`;

export const WarningTextContainer = styled(_Text)`
  font-family: ${({theme: {fonts}}) => fonts.poppins_light};
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`;

export const WarningText = styled(_Text)`
  color: ${({theme}) => theme.colors.red};
  font-family: ${({theme: {fonts}}) => fonts.poppins_light};
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`;

export const Button = styled.button`
  width: 17%;
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.yellow};
  @media screen and (max-width: 480px) {
    width: 35vw;
  }
`;
