import React, {useContext, useState} from 'react';

import {useTheme} from 'styled-components';
import {AuthContext} from 'context/AuthContext';
import {useHistory, useLocation} from 'react-router-dom';

import background from 'assets/images/backgroundLogin.png';

import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import PlayingCard from 'components/PlayingCard';

import {BiUser, BiLockOpenAlt} from 'react-icons/bi';

import {
  Row,
  Group,
  Container,
  FormHeader,
  FormMiddle,
  FormFooter,
  FormContainer,
} from './styles';

const ResetPassword: React.FC = () => {
  const {isMobile} = useTheme();
  const location: any = useLocation();

  const [username, setUserName] = useState(location.state?.username || '');
  const [password, setPassword] = useState('');
  const [resetPasswordCode, setResetPasswordCode] = useState('');

  const {actions: authActions} = useContext(AuthContext);
  const {colors, fonts} = useTheme();
  const history = useHistory();

  const resetPassword = async () => {
    //TODO validar se o usuario preencheu a senha e o código de recuperação
    const result = await authActions.resetPassword(
      username,
      password,
      resetPasswordCode,
    );
    if (!result.ok) {
      history.push('/');
    }
  };

  return (
    <Container style={customContainerStyle}>
      {!isMobile && (
        <Group>
          <PlayingCard time={5}></PlayingCard>
        </Group>
      )}
      <Group backgroundColor={colors.primary}>
        <FormContainer>
          <FormHeader>
            <Text size={20} font={fonts.poppins_light}>
              Redefina sua{' '}
              <Text size={20} color={colors.yellow} font={fonts.poppins_medium}>
                &nbsp; SENHA
              </Text>
            </Text>
          </FormHeader>
          <FormMiddle>
            <Input
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Email"
              icon={() => <BiUser />}
            />
            <Input
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Nova Senha"
              icon={() => <BiLockOpenAlt />}
            />
            <Input
              value={resetPasswordCode}
              onChange={(e) => setResetPasswordCode(e.target.value)}
              placeholder="Código de Recuperação"
              icon={() => <BiLockOpenAlt />}
            />
          </FormMiddle>
          <FormFooter>
            <Row>
              <Button onClick={resetPassword}>CONFIRMAR</Button>
            </Row>
          </FormFooter>
        </FormContainer>
      </Group>
    </Container>
  );
};

const customContainerStyle = {
  backgroundImage: `url(${background})`,
};

export default React.memo(ResetPassword);
