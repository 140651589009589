import styled from 'styled-components';

import {BarProps} from './types';

export const TRANSITION_DURATION = 0.5;

export const Container = styled.div`
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  flex-direction: row;
  z-index: 10;
`;
export const BlackArea = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
`;

export const Bar = styled.div<BarProps>`
  width: 15%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition-duration: ${TRANSITION_DURATION}s;
  background: ${({theme}) => theme.colors.primary_dark};
  transform: translateX(${({isVisible}) => (isVisible ? 0 : 100)}%);
  @media screen and (max-width: 480px) {
    width: 50%;
  }
`;
export const ImageHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 10% 0px;
  justify-content: center;
`;
export const ImageHeader = styled.img`
  width: 30%;
  height: auto;
`;
export const BarContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const BarFooter = styled.div``;
