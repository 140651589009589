import React, {useMemo, useContext} from 'react';

import {useTheme} from 'styled-components';
import {SidebarContext} from 'context/SidebarContext';

import {HeaderProps} from './types';
import Creditos from '../../pages/App/Home/components/Creditos';
import {AiOutlineMenu} from 'react-icons/ai';
import UserWelcome from 'components/UserWelcome';

import {
  HeaderRow,
  ButtonContainer,
  HeaderItem,
  HeaderContainer,
} from './styles';

const Header: React.FC<HeaderProps> = ({hideCreditos}) => {
  const {colors, isMobile} = useTheme();
  const {
    actions: {openSidebar},
  } = useContext(SidebarContext);

  const showCreditos = useMemo(() => {
    if (hideCreditos || isMobile) {
      return false;
    }
    return true;
  }, [hideCreditos, isMobile]);
  return (
    <HeaderContainer>
      <HeaderRow>
        <HeaderRow>
          <HeaderItem>
            <UserWelcome />
          </HeaderItem>
          <HeaderItem />
        </HeaderRow>
        <HeaderRow>
          <HeaderItem>{showCreditos && <Creditos />}</HeaderItem>
          <HeaderItem>
            <ButtonContainer onClick={openSidebar}>
              <AiOutlineMenu size={30} color={colors.tertiary_text} />
            </ButtonContainer>
          </HeaderItem>
        </HeaderRow>
      </HeaderRow>
    </HeaderContainer>
  );
};

export default React.memo(Header);
