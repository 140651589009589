import styled from 'styled-components';

import {SidebarContainerProps} from './types';

import Text from 'components/Text';

export const Container = styled.div<SidebarContainerProps>`
  display: flex;
  padding: 10px 0px 10px 20px;
  justify-content: flex-start;
  align-items: center;
  ${({borderActive, borderColor}) =>
    borderActive &&
    `
   border: solid ${borderColor};
   border-width: 0px 0px 0px 1px;
  `}

  :hover {
    transform: scale(1.05);
  }
`;

export const RouteName = styled(Text)`
  margin-left: 10px;
  font-size: 16px;
  font-family: ${({theme}) => theme.fonts.poppins_light};

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;
