import React from 'react';

import AuthProvider from './AuthContext';
import UserProvider from './UserContext';
import SidebarProvider from './SidebarContext';
import ThemeProvider from './ThemeContext';
import SorteioProvider from './SorteioContext';
import MaterialUIProvider from './MaterialUIContext';

const Context: React.FC = ({children}) => {
  return (
    <ThemeProvider>
      <MaterialUIProvider>
        <UserProvider>
          <SorteioProvider>
            <AuthProvider>
              <SidebarProvider>{children}</SidebarProvider>
            </AuthProvider>
          </SorteioProvider>
        </UserProvider>
      </MaterialUIProvider>
    </ThemeProvider>
  );
};

export default Context;
