import React, {useRef, useContext, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';
import {AuthContext} from 'context/AuthContext';

import background from 'assets/images/backgroundLogin.png';

import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import SnackAlert, {SnackAlertMethods} from 'components/SnackAlert';
import PlayingCard from 'components/PlayingCard';

import {BiUser, BiLockOpenAlt} from 'react-icons/bi';

import {
  Row,
  Group,
  Container,
  FormHeader,
  FormMiddle,
  FormFooter,
  FormContainer,
  ForgotPassword,
  ForgotPasswordContainer,
} from './styles';

const Login: React.FC = () => {
  const {isMobile} = useTheme();
  const snackRef = useRef<SnackAlertMethods>({} as SnackAlertMethods);

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const {actions: authActions} = useContext(AuthContext);
  const {colors, fonts} = useTheme();
  const history = useHistory();

  const auth = async () => {
    const result = await authActions.signIn(username, password);
    if (result.ok) {
      history.push('/');
      return;
    } else {
      snackRef.current.open({message: result.message, severity: 'error'});
    }
  };
  const forgotPassword = async () => {
    history.push('/forgotPassword');
  };

  return (
    <Container style={customContainerStyle}>
      <SnackAlert ref={snackRef} />
      {!isMobile && (
        <Group>
          <PlayingCard time={5}></PlayingCard>
        </Group>
      )}
      <Group backgroundColor={colors.primary}>
        <FormContainer>
          <FormHeader>
            <Text size={20} font={fonts.poppins_light}>
              FAÇA SEU{' '}
              <Text size={20} color={colors.yellow} font={fonts.poppins_medium}>
                &nbsp; LOGIN,
              </Text>
            </Text>
            <Text size={25}>E QUE OS JOGOS COMECEM!</Text>
          </FormHeader>
          <FormMiddle>
            <Input
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Email"
              icon={() => <BiUser />}
            />
            <Input
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Senha"
              icon={() => <BiLockOpenAlt />}
            />
          </FormMiddle>
          <FormFooter>
            <Row>
              <Button onClick={auth}>ENTRAR</Button>
              <ForgotPasswordContainer
                onClick={forgotPassword}
                font={fonts.poppins_light}>
                Esqueceu sua
                <ForgotPassword onClick={forgotPassword}>
                  &nbsp;senha?
                </ForgotPassword>
              </ForgotPasswordContainer>
            </Row>
          </FormFooter>
        </FormContainer>
      </Group>
    </Container>
  );
};

const customContainerStyle = {
  backgroundImage: `url(${background})`,
};

export default React.memo(Login);
