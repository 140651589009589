import React, {useContext} from 'react';

import {useTheme} from 'styled-components';

import Text from 'components/Text';
import coinStar from 'assets/images/coinStar.png';
import {SorteioContext} from 'context/SorteioContext';

import {
  Icon,
  CreditosText,
  TextBackground,
  CreditosContainer,
  CreditosInnerContainer,
} from './styles';

const Creditos: React.FC = () => {
  const {colors, fonts} = useTheme();
  const {
    state: {creditos},
  } = useContext(SorteioContext);

  return (
    <CreditosContainer>
      <CreditosText
        size={20}
        font={fonts.poppins_medium}
        color={colors.tertiary_text}>
        Créditos
      </CreditosText>
      <CreditosInnerContainer>
        <Icon src={coinStar} />
        <TextBackground>
          <Text
            size={25}
            font={fonts.poppins_semiBold}
            color={colors.secundary_text}>
            {creditos}
          </Text>
        </TextBackground>
      </CreditosInnerContainer>
    </CreditosContainer>
  );
};
export default React.memo(Creditos);
