import styled from 'styled-components';

import background from 'assets/images/backgroundApp.png';

export const Container = styled.div.attrs({className: 'container'})`
  display: flex;
  align-items: center;
  flex-direction: colunm;
  justify-content: center;
  background-color: #130f0f;
  background-position: center;
  background-image: url(${background});
`;

export const InnerContainer = styled.div`
  width: 90%;
  height: 90%;
  overflow: hidden;
  position: relative;
  border-radius: 2%;
  background-color: ${({theme}) => theme.colors.primary};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
