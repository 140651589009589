import React from 'react';

import {DialogProps} from './types';

import {
  Button,
  Container,
  DialogContainer,
  DialogTitle,
  DialogContentText,
  ButtonText,
} from './styles';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const Dialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  agreeButton,
  dialogTitle,
  disagreeButton,
  dialogContent,
}) => {
  return (
    <DialogContainer
      PaperProps={{
        style: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
      }}
      open={open}
      onClose={handleClose}>
      <Container>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('disagree')} color="primary">
            <ButtonText>{disagreeButton}</ButtonText>
          </Button>
          {agreeButton && (
            <Button onClick={() => handleClose('agree')} color="primary">
              <ButtonText>{agreeButton}</ButtonText>
            </Button>
          )}
        </DialogActions>
      </Container>
    </DialogContainer>
  );
};

export default React.memo(Dialog);
