import React, {useMemo, useContext} from 'react';

import {useTheme} from 'styled-components';
import {UserContext} from 'context/UserContext';

import userImage from 'assets/images/userImage.png';
import {
  Colunm,
  Welcome,
  UserName,
  Container,
  UserImage,
  TextBackground,
} from './styles';

const UserWelcome: React.FC = () => {
  const {
    state: {user},
  } = useContext(UserContext);
  const {colors} = useTheme();
  const name = useMemo(() => {
    try {
      const [primeiroNome] = user?.nome?.split(' ');
      return primeiroNome;
    } catch (error) {
      return '';
    }
  }, [user.nome]);
  return (
    <Container>
      <UserImage src={userImage} />
      <Colunm>
        <TextBackground backgroundColor={colors.primary_dark}>
          <Welcome>Bem vindo (a)</Welcome>
        </TextBackground>
        <TextBackground backgroundColor={colors.secundary}>
          <UserName>{name}</UserName>
        </TextBackground>
      </Colunm>
    </Container>
  );
};

export default React.memo(UserWelcome);
