import React from 'react';

import {SaldoMobileProps} from './types';

import CardInfo from 'components/CardInfo';
import {Container} from './styles';

const SaldoMobile: React.FC<SaldoMobileProps> = ({creditos}) => {
  return (
    <Container>
      {creditos.map((element, index) => (
        <CardInfo title={`N° Cotação ${element.idExterno}`} key={index}>
          <CardInfo.Item
            label="Data"
            value={new Date(element.dataPremiação).toLocaleDateString('pt-BR')}
          />
          <CardInfo.Item
            label="Valor"
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(element.valor as number)}
          />
        </CardInfo>
      ))}
    </Container>
  );
};

export default React.memo(SaldoMobile);
