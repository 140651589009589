import { create } from 'apisauce';

const api = create({
  baseURL: 'https://gerenciarplay.com.br/api/api/',
});

api.addAsyncRequestTransform((request) => async () => {
  const token = localStorage.getItem('token');
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
});
export default api;
