import styled from 'styled-components';
import {TextBackgroundProps} from './types';

import Text from 'components/Text';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const UserImage = styled.img`
  border-radius: 500px;
  width: 50px;
  height: auto;
  left: 18px;
  position: relative;
`;

export const Colunm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextBackground = styled.div<TextBackgroundProps>`
  width: fit-content;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px 20px;
  max-width: 30vw;
  background-color: ${({backgroundColor}) => backgroundColor || 'red'};
`;

export const Welcome = styled(Text)`
  font-size: 13px;
  font-family: ${({theme}) => theme.fonts.poppins_light};

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`;

export const UserName = styled(Text)`
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.colors.secundary_text};
  font-family: ${({theme}) => theme.fonts.poppins_semiBold};

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
