import React from 'react';

import {Switch, Route} from 'react-router-dom';

import Home from 'pages/App/Home';
import Game from 'pages/App/Game';

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={'/'} component={Home} />
      <Route exact path={`/game`} component={Game} />
    </Switch>
  );
};

export default AppRoutes;
