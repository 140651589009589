import styled from 'styled-components';
import Text from 'components/Text';

export const CreditosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CreditosText = styled(Text)`
  position: relative;
  left: 10px;
  margin-bottom: 0px;
`;
export const CreditosInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Icon = styled.img`
  width: 55px;
  position: relative;
  left: 10px;
  height: auto;
`;
export const TextBackground = styled.div`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  justify-content: center;
  display: flex;
  width: 100px;
  background: ${({theme}) => theme.colors.secundary};
`;
