import React, {useState, useEffect, useMemo} from 'react';

import {premioService} from 'services';
import {ExtratoPagamento} from 'models';
import {useTheme} from 'styled-components';

import {Title} from './styles';
import Header from 'components/Header';
import Container from 'components/PageContainer';
import Mobile from './components/ExtratoPagamentoMobile';
import Desktop from './components/ExtratoPagamentoDesktop';

const SaldoBloqueado: React.FC = () => {
  const {isMobile} = useTheme();
  const [_extratoPagamento, setExtratoPagamento] = useState<ExtratoPagamento[]>(
    [],
  );

  useEffect(() => {
    const loadExtratoPagamento = async () => {
      try {
        const response = await premioService.listarExtratoPagamento();
        setExtratoPagamento(response);
      } catch (error) {}
    };
    loadExtratoPagamento();
  }, []);

  const extratoPagamento: ExtratoPagamento[] = useMemo(() => {
    return _extratoPagamento.map((e) => ({
      ...e,
      referencia: e.referencia.substr(3, 10),
    }));
  }, [_extratoPagamento]);
  return (
    <Container>
      <Header hideCreditos />
      <Title>Extrato de Pagamento</Title>
      {isMobile ? (
        <Mobile extratoPagamento={extratoPagamento} />
      ) : (
        <Desktop extratoPagamento={extratoPagamento} />
      )}
    </Container>
  );
};

export default React.memo(SaldoBloqueado);
