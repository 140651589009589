import React, {useContext} from 'react';

import {ItemProps} from './types';
import {currencyFormat} from 'utils/mask';
import {useTheme} from 'styled-components';
import {SorteioContext} from 'context/SorteioContext';

import {Text, Label, Container, ItemBackground, ItemContainer} from './styles';

const Saldo: React.FC = () => {
  const {
    state: {saldo},
  } = useContext(SorteioContext);
  return (
    <Container>
      <Item
        label="Saldo Bloqueado"
        value={currencyFormat(saldo.saldoBloqueado)}
      />
      <Item
        label="Saldo Liberado"
        value={currencyFormat(saldo.saldoLiberado)}
      />
    </Container>
  );
};
const Item: React.FC<ItemProps> = ({label, value}) => {
  const {fonts, colors} = useTheme();
  return (
    <ItemContainer>
      <ItemBackground>
        <Label font={fonts.poppins_semiBold}>{label}</Label>
      </ItemBackground>
      <ItemBackground backgroundColor={colors.secundary}>
        <Text color={colors.secundary_text} font={fonts.poppins_semiBold}>
          {value}
        </Text>
      </ItemBackground>
    </ItemContainer>
  );
};
export default React.memo(Saldo);
