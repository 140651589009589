import React from 'react';

import fonts from 'constants/fonts';
import colors from 'constants/colors';

import {useMediaQuery} from 'react-responsive';
import {ThemeProvider} from 'styled-components';

const _ThemeProvider: React.FC = ({children}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)',
  });

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1024px)',
  });

  return (
    <ThemeProvider theme={{colors, fonts, isMobile, isDesktopOrLaptop}}>
      {children}
    </ThemeProvider>
  );
};

export default _ThemeProvider;
