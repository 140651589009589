import {CreditoDetalhado} from 'models';
import React, {useState, useEffect} from 'react';

import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {CreditosDesktopProps} from './types';

import {Container, DataGridContainer} from './styles';

const CreditosDesktop: React.FC<CreditosDesktopProps> = ({
  creditos: _creditos,
}) => {
  const [creditos, setCreditos] = useState<CreditoDetalhado[]>([]);

  useEffect(() => {
    const temp = _creditos.map((e, index) => ({
      ...e,
      id: index,
      dataCriacao: new Date(e.dataCriacao).toLocaleDateString('pt-BR'),
    }));
    setCreditos(temp);
  }, [_creditos]);

  return (
    <Container>
      <DataGridContainer>
        <DataGrid rows={creditos} columns={columns} />
      </DataGridContainer>
    </Container>
  );
};

const columns: GridColDef[] = [
  {field: 'idExterno', headerName: 'Nº Cotação', width: 150},
  {field: 'dataCriacao', headerName: 'Data', width: 150},
  {field: 'situacao', headerName: 'Situação', width: 150},
];

export default CreditosDesktop;
