import {Premio, SaldoDashboard, SorteioVigente} from 'models';

//#region State
export enum SorteioTypes {
  SetSorteio = 'SET_SORTEIO',
  SetShowHowToPlay = 'SET_SHOW_HOW_TO_PLAY',
}

interface SetSorteioReducer {
  type: typeof SorteioTypes.SetSorteio;
  saldo: SaldoDashboard;
  creditos: number;
  premios: Premio[];
  sorteioVigente: SorteioVigente;
}
interface SetShowHowToPlayReducer {
  type: typeof SorteioTypes.SetShowHowToPlay;
}

type Action = SetSorteioReducer | SetShowHowToPlayReducer;

export interface ContextState {
  creditos: number;
  premios: Premio[];
  saldo: SaldoDashboard;
  sorteioVigente: SorteioVigente;
  showHowToPlay: boolean;
}

export type Reducer = (prevState: ContextState, action: Action) => ContextState;

//#endregion
//#region Actions

//#region BuscarSorteio
type AtualizarSorteio = () => void;
//#endregion

//#region
type DisableHowToPlay = () => void;
//#endregion

export interface ContextActions {
  atualizarSorteio: AtualizarSorteio;
  disableHowToPlay: DisableHowToPlay;
}

//#endregion

export interface Context {
  state: ContextState;
  actions: ContextActions;
}
