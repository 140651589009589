import styled from 'styled-components';
import {ContainerProps} from './types';

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1;
  height: 40%;
  flex: 0 0 13.3333%;

  transform-style: preserve-3d;
  transition: transform ${({time}) => time}s cubic-bezier(0, 0.75, 0.25, 1);
  transition: -webkit-transform ${({time}) => time}s
    cubic-bezier(0, 0.75, 0.25, 1) 0s;
  transform: rotateY(${({angle}) => angle}deg);
  user-select: none;
  border: none;
`;

export const CardImage = styled.img`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
`;

export const Face = styled.div`
  height: auto;
  backface-visibility: hidden;
`;
export const FaceBack = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;
