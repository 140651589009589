import styled from 'styled-components';

import Text from 'components/Text';

export const Container = styled.div`
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  background: ${({theme}) => theme.colors.primary_dark};
`;

export const Title = styled(Text)`
  margin: 10px 0px;
  font-size: 16px;
  color: ${({theme}) => theme.colors.yellow};
  font-family: ${({theme}) => theme.fonts.poppins_semiBold};
`;

//#region Item
export const ItemContainer = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
`;
export const Label = styled(Text)`
  color: ${({theme}) => theme.colors.tertiary_text};
  font-family: ${({theme}) => theme.fonts.poppins_medium};
`;
export const Value = styled(Text)`
  font-family: ${({theme}) => theme.fonts.poppins_semiBold};
`;
//#endregion
