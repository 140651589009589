import React from 'react';

import {Switch, useRouteMatch} from 'react-router-dom';

import NoMatch from 'pages/NoMatch';

import GuardRoute from 'components/GuardRoute';

import Creditos from 'pages/App/Detalhamento/Creditos';
import SaldoLiberado from 'pages/App/Detalhamento/SaldoLiberado';
import SaldoBloqueado from 'pages/App/Detalhamento/SaldoBloqueado';
import ExtratoPagamento from 'pages/App/Detalhamento/ExtratoPagamento';

const Detalhamento: React.FC = () => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <GuardRoute
        type="private"
        path={`${path}/creditos`}
        component={Creditos}
      />
      <GuardRoute
        type="private"
        path={`${path}/saldoliberado`}
        component={SaldoLiberado}
      />
      <GuardRoute
        type="private"
        path={`${path}/saldobloqueado`}
        component={SaldoBloqueado}
      />
      <GuardRoute
        type="private"
        path={`${path}/extratopagamento`}
        component={ExtratoPagamento}
      />
      <GuardRoute type="public" path="*">
        <NoMatch />
      </GuardRoute>
    </Switch>
  );
};

export default Detalhamento;
