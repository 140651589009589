import { Colors } from 'models';
//TODO Adicionar as cores do app aqui

const colors: Colors = {
  primary: '#131414',
  primary_dark: '#0C0B0B',
  secundary: '#FFFFFF',
  red: '#D70A0A',
  yellow: '#e6a80e',
  //Text
  primary_text: '#DCDCDC',
  secundary_text: '#000000',
  tertiary_text: '#A5A5A5',
};

export default colors;
