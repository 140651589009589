import styled from 'styled-components';

import Text from 'components/Text';

export const Title = styled(Text)`
  font-size: 24px;
  margin: 10px 2%;

  color: ${({theme}) => theme.colors.primary_text};
  font-family: ${({theme}) => theme.fonts.poppins_medium};

  @media screen and (max-width: 480px) {
    margin: 0px 10px;
  }
`;
