import styled from 'styled-components'

export const Container = styled.div`
  top: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 15%;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
`

export const InnerContainer = styled.div`
  display: flex;
  width: 40%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const CoinIcon = styled.img`
  width: 40px;
  height: auto;
`
