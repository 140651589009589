import React from 'react';

import {useHistory} from 'react-router';

import Header from '../../../components/Header';
import Middle from './components/Middle';
import Footer from './components/Footer';

import {Container, Content, InnerContainer} from './styles';

const Home: React.FC = () => {
  const history = useHistory();

  const onPlay = () => {
    history.push('/game');
  };
  return (
    <Container>
      <InnerContainer>
        <Content>
          <Header />
          <Middle onPlay={onPlay} />
          <Footer onPlay={onPlay} />
        </Content>
      </InnerContainer>
    </Container>
  );
};

export default React.memo(Home);
