import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  height: 75%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const DataGridContainer = styled.div`
  height: 80%;
  width: 700px;
`;
