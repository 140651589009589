import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

//#region Item

//#endregion

//#region Premio

export const UltimosPremiosContainer = styled.div`
  align-self: center;
  width: 70%;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 2px 5px rgba(0, 0, 0, 0.5);
`;

export const PremioContainer = styled.div`
  width: 95%;
  margin: 5% 0px 0px;
  border: solid #545454;
  border-width: 0px 0px 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

//#endregion
