import styled from 'styled-components';

const EXTREMITY_HEIGHT = 15;

//#region Header
export const HeaderContainer = styled.div`
  width: 100%;
  height: ${EXTREMITY_HEIGHT}%;
  display: flex;
`;
export const HeaderRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background: transparent;
  padding: 10px 20px;
`;
//#endregion
