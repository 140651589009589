import React from 'react';

import {FooterModalProps} from './types';

import Text from 'components/Text';
import {
  Button,
  WarningText,
  WarningTextContainer,
  FooterContainer,
} from './styles';

const Footer: React.FC<FooterModalProps> = ({onPlay}) => {
  return (
    <FooterContainer>
      <WarningTextContainer>
        <WarningText>ATENÇÃO:&nbsp;</WarningText>Se ja existir uma partida em
        andamento, vamos continuar de onde você parou estornado.
      </WarningTextContainer>
      <Button onClick={onPlay}>
        <Text size={20}>JOGAR</Text>
      </Button>
    </FooterContainer>
  );
};

export default React.memo(Footer);
