import styled from 'styled-components'
import { PlayingCardProps } from './types'

export const Card = styled.div<PlayingCardProps>`
  display: flex;
  height: auto;
  width: auto;

  transform-style: preserve-3d;
  transition: transform ${({ time }) => time}s cubic-bezier(0, 0.75, 0.25, 1);
  transition: -webkit-transform ${({ time }) => time}s
    cubic-bezier(0, 0.75, 0.25, 1) 0s;
  transform: rotateY(${({ angle }) => angle}deg);
  user-select: none;
`

export const Face = styled.div`
  height: auto;
  backface-visibility: hidden;
`

export const FaceBack = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`

export const CardImage = styled.img`
  flex: 1;
  width: 25vw;
  height: auto;
`
