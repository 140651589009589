import styled from 'styled-components';

import Text from 'components/Text';

const EXTREMITY_HEIGHT = 15;

export const Title = styled(Text)`
  font-size: 30px;
  font-family: ${({theme}) => theme.fonts.poppins_semiBold};
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  height: ${EXTREMITY_HEIGHT}vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const HeaderItem = styled.button`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: none;

  @media screen and (max-width: 480px) {
    width: 20%;
  }
`;
