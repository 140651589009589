import {User} from 'models';

//#region State
export enum UserTypes {
  SetUser = 'SET_USER',
}

interface SetUserReducer {
  type: typeof UserTypes.SetUser;
  user: User;
}

type UserAction = SetUserReducer;

export interface ContextState {
  user: User;
}

export type Reducer = (
  prevState: ContextState,
  action: UserAction,
) => ContextState;

//#endregion
//#region Actions

//#region SetUser
type SetUser = (data: User) => void;
//#endregion

export interface ContextActions {
  setUser: SetUser;
}
//#endregion

export interface Context {
  state: ContextState;
  actions: ContextActions;
}
