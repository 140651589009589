import React, {useContext} from 'react';

import {GuardProps} from './types';
import {AuthContext} from 'context/AuthContext';
import {Route, Redirect} from 'react-router-dom';

const Guard = ({type, ...rest}: GuardProps) => {
  const {
    state: {token},
  } = useContext(AuthContext);
  if (type === 'private' && !token) {
    return <Redirect to="/auth" />;
  }
  return <Route {...rest} />;
};

export default React.memo(Guard);
