import React from 'react';

import {InputProps} from './types';

import {Row, TextInput, Container, Placeholder, IconContainer} from './styles';

const Input: React.FC<InputProps> = ({
  type,
  placeholder,
  icon,
  onChange,
  value = '',
}) => {
  return (
    <Container>
      {placeholder && <Placeholder>{placeholder}</Placeholder>}
      <Row>
        <IconContainer>{icon && icon()}</IconContainer>
        <TextInput type={type} onChange={onChange} value={value} />
      </Row>
    </Container>
  );
};

export default React.memo(Input);
