import React from 'react';

import {CreditosMobileProps} from './types';

import CardInfo from 'components/CardInfo';
import {Container} from './styles';

const CreditosMobile: React.FC<CreditosMobileProps> = ({creditos}) => {
  return (
    <Container>
      {creditos.map((element, index) => (
        <CardInfo title={`Nº Cotação ${element.idExterno}`} key={index}>
          <CardInfo.Item
            label="Data"
            value={new Date(element.dataCriacao).toLocaleDateString('pt-BR')}
          />
          <CardInfo.Item label="Situação" value={element.situacao} />
        </CardInfo>
      ))}
    </Container>
  );
};

export default React.memo(CreditosMobile);
