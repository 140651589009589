import styled from 'styled-components';

import {GroupProps} from './types';

export const Container = styled.div.attrs({
  className: 'container',
})`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const Group = styled.div<GroupProps>`
  display: flex;
  background: ${({backgroundColor}) => backgroundColor || 'transparent'};
  width: 50vw;
  height: 100vh;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 100vw;
  }
`;

//#region Form
export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 10%;
  flex-direction: column;
  justify-content: space-around;
`;
export const FormHeader = styled.div``;
export const FormMiddle = styled.div`
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const FormFooter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5vh;
  width: 100%;
`;

//#endregion
