import React, {useState, useEffect} from 'react';

import {Saldo} from 'models';
import {SaldoDesktopProps} from './types';
import {DataGrid, GridColDef} from '@mui/x-data-grid';

import {Container, DataGridContainer} from './styles';

const SaldoDesktop: React.FC<SaldoDesktopProps> = ({saldo: _saldo}) => {
  const [saldo, setCreditos] = useState<Saldo[]>([]);

  useEffect(() => {
    const temp = _saldo.map((e, index) => ({
      ...e,
      id: index,
      valor: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(e.valor as number),
      dataPremiacao: new Date(e.dataPremiação).toLocaleDateString('pt-BR'),
    }));
    setCreditos(temp);
  }, [_saldo]);

  return (
    <Container>
      <DataGridContainer>
        <DataGrid rows={saldo} columns={columns} />
      </DataGridContainer>
    </Container>
  );
};

const columns: GridColDef[] = [
  {field: 'idExterno', headerName: 'Nº Cotação', width: 150},
  {field: 'dataPremiacao', headerName: 'Data', width: 150},
  {field: 'valor', headerName: 'Valor', width: 150},
];

export default React.memo(SaldoDesktop);
