import React, {useMemo} from 'react';

import Lottie from 'react-lottie';
import {useTheme} from 'styled-components';

import {IoIosArrowRoundBack} from 'react-icons/io';
import Container from 'components/PageContainer';
import animation404 from 'assets/lottie/404.json';
import {Title, Content, BackButtonContainer} from './styles';

const NoMatch: React.FC = () => {
  const {colors, isMobile} = useTheme();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation404,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const size = useMemo(() => {
    if (isMobile) {
      return 300;
    }
    return 500;
  }, [isMobile]);
  return (
    <Container>
      <Content>
        <Lottie
          options={defaultOptions}
          height={size}
          width={size}
          isPaused={false}
        />
        <Title>Parece que essa pagina não existe</Title>
        <BackButtonContainer to="/">
          <IoIosArrowRoundBack color={colors.primary_text} size={26} />
          Voltar para uma que existe
        </BackButtonContainer>
      </Content>
    </Container>
  );
};

export default NoMatch;
