import {CurrencyFormat} from './types';
//#region currencyFormat

/**Adiciona mascara de dinheiro em um número*/
export const currencyFormat: CurrencyFormat = (moneyValue) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(moneyValue);
};

//#endregion
