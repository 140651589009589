import styled from "styled-components";

export const Container = styled.button`
  min-height: 50px;
  width: 150px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.yellow};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
