import React, { useMemo, createContext, useContext, useReducer } from 'react';

import { authService } from 'services';
import { UserContext } from 'context/UserContext';
import { AuthTypes, Context, ContextActions, Reducer } from './types';

export const AuthContext = createContext<Context>({} as Context);

const AuthProvider: React.FC = ({ children }) => {
  const { actions: userAction } = useContext(UserContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions: ContextActions = useMemo(
    () => ({
      signIn: async (userName, password) => {
        try {
          const response = await authService.authorize({
            userName,
            password,
            grantType: 'password',
          });
          const type =
            response.idTipoUsuario === 2 ? 'Administrador' : 'Jogador';
          const {
            userName: username,
            accessToken,
            refreshToken,
            nome,
          } = response;
          const currentuser = {
            username,
            accessToken,
            refreshToken,
            nome,
            usertype: type,
          };

          localStorage.setItem('currentUser', JSON.stringify(currentuser));
          if (response.idTipoUsuario === 2) {
            window.location.href =
              'https://gerenciarplay.com.br/backoffice/#/dashboards/dashboard-1';
            return {
              ok: true,
              message: 'Logado como Administrador',
            };
          } else {
            userAction.setUser(response);
            localStorage.setItem('token', response.accessToken);
            dispatch({ type: AuthTypes.SignIn, token: response.accessToken });
            return {
              ok: true,
              message: 'Logado como jogador',
            };
          }
        } catch (error: any) {
          return {
            ok: false,
            message: error.message,
          };
        }
      },
      signOut: () => {
        localStorage.clear();
        dispatch({ type: AuthTypes.SignOut });
      },
      forgotPassword: async (userName) => {
        try {
          await authService.forgotPassword(userName);
          return {
            ok: true,
            message:
              'Verifique seu E-Mail, enviamos um código de recuperação para você!',
          };
        } catch (error: any) {
          return {
            ok: false,
            message: error,
          };
        }
      },
      resetPassword: async (userName, password, resetPasswordCode) => {
        try {
          await authService.resetPassword({
            userName,
            password,
            resetPasswordCode,
          });
          return {
            ok: true,
            message: 'Senha definida com Sucesso',
          };
        } catch (error: any) {
          return {
            ok: false,
            message: error,
          };
        }
      },
    }),
    [userAction],
  );

  return (
    <AuthContext.Provider value={{ state, actions }}>
      {children}
    </AuthContext.Provider>
  );
};

const initialState = {
  token: localStorage.getItem('token'),
};

const reducer: Reducer = (prevState, action) => {
  switch (action.type) {
    case AuthTypes.SignIn:
      return { ...prevState, token: action.token };
    case AuthTypes.RestoreToken:
      return { ...prevState, token: action.token };
    case AuthTypes.SignOut:
      return { ...prevState, token: null };
    default:
      return { ...prevState };
  }
};
export default AuthProvider;
