import React, { useMemo } from "react";
import { ButtonProps } from "./types";

import Text from "components/Text";
import { Container } from "./styles";

const Button: React.FC<ButtonProps> = ({
  style,
  children: _children,
  onClick,
}) => {
  const children = useMemo(() => {
    if (typeof _children === "string") {
      return <Text size={20}>{_children}</Text>;
    }
    return _children;
  }, [_children]);
  return (
    <Container onClick={onClick} style={style}>
      {children}
    </Container>
  );
};

export default React.memo(Button);
