import React, {useMemo} from 'react';

import {SidebarItemProps} from './types';
import {useTheme} from 'styled-components';

import {RouteName, Container} from './styles';

const SidebarItem: React.FC<SidebarItemProps> = ({
  menu,
  activeRoute,
  onItemClick,
}) => {
  const {Icon, name} = menu;
  const {colors} = useTheme();

  const isActive = useMemo(
    () => activeRoute === menu.route,
    [activeRoute, menu.route],
  );

  const color = useMemo(() => {
    if (isActive) {
      return colors.yellow;
    }
    return colors.secundary;
  }, [colors, isActive]);
  return (
    <Container
      onClick={() => onItemClick(menu.route)}
      borderActive={isActive}
      borderColor={color}>
      <Icon color={color} />
      <RouteName color={color}>{name}</RouteName>
    </Container>
  );
};

export default SidebarItem;
