import styled from 'styled-components';

import _Text from 'components/Text';
import {ItemBackgroundProps} from './types';

export const Container = styled.div`
  width: 95%;
  margin: 10% 0px;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const Text = styled(_Text)`
  font-size: 90%;
`;

//#region Item

export const Label = styled(_Text)`
  font-size: 14px;
  font-family: ${({theme}) => theme.fonts.poppins_semiBold};

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  flex-direction: column;
  width: 35%;
`;
export const ItemBackground = styled.div<ItemBackgroundProps>`
  padding: 10px 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: ${({theme, backgroundColor}) =>
    backgroundColor || theme.colors.primary_dark};
`;
//#endregion
