import React from 'react';

import {ExtratoPagamentoMobileProps} from './types';

import CardInfo from 'components/CardInfo';
import {Container} from './styles';

const ExtratoPagamentoMobile: React.FC<ExtratoPagamentoMobileProps> = ({
  extratoPagamento,
}) => {
  return (
    <Container>
      {extratoPagamento.map((element, index) => (
        <CardInfo key={index} title={`Competencia: ${element.referencia}`}>
          <CardInfo.Item
            label="Valor"
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(element.valor as number)}
          />
        </CardInfo>
      ))}
    </Container>
  );
};

export default React.memo(ExtratoPagamentoMobile);
