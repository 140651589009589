import React from 'react';

import {HomeMiddleProps} from './types';
import {useTheme} from 'styled-components';

import Saldo from '../Saldo';
import Premios from '../Premios';
import {
  Divisor,
  Group,
  PlayButton,
  MiddleContainer,
  PlayButtonContainer,
} from './styles';

import playButton from 'assets/images/playButton.png';

const Middle: React.FC<HomeMiddleProps> = ({onPlay}) => {
  const {isMobile} = useTheme();

  return (
    <MiddleContainer>
      <Group justifyContent={isMobile ? 'flex-start' : 'space-between'}>
        <Saldo />
        <Premios />
      </Group>
      {!isMobile && (
        <>
          <Divisor />
          <Group justifyContent="center">
            <PlayButtonContainer onClick={onPlay}>
              <PlayButton src={playButton} />
            </PlayButtonContainer>
          </Group>
        </>
      )}
    </MiddleContainer>
  );
};

export default React.memo(Middle);
