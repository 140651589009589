import styled from 'styled-components';

import {GroupProps} from './types';

export const PlayButtonContainer = styled.button`
  box-shadow: none;
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  background: transparent;
`;
export const PlayButton = styled.img`
  width: 100%;
`;

//#region Middle
export const MiddleContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;
export const Group = styled.div<GroupProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({justifyContent}) => justifyContent || 'space-between'};
`;
export const Divisor = styled.div`
  width: 1px;
  background-color: ${({theme}) => theme.colors.secundary};
`;
