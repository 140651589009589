import styled from 'styled-components';
import {TextProps} from './types';

export const TextBase = styled.span<TextProps>`
  display: flex;
  color: ${({theme, color}) => color || theme.colors.primary_text};
  font-size: ${({size}) => size || 14}px;
  font-family: ${({font, theme}) => font || theme.fonts.poppins_medium};
  margin: 0px;
  cursor: ${({onClick}) => (onClick ? 'pointer' : 'default')};
`;
