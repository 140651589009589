import React, {useMemo, useState, createContext} from 'react';

import {Context, ContextActions} from './types';

export const SidebarContext = createContext<Context>({} as Context);

const SidebarProvider: React.FC = ({children}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const actions: ContextActions = useMemo(
    () => ({
      openSidebar: () => {
        setIsVisible(true);
      },
      closeSidebar: () => {
        setIsVisible(false);
      },
    }),
    [],
  );
  return (
    <SidebarContext.Provider value={{actions, state: {isVisible}}}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
