import React from 'react';

import {Card, ItemProps} from './types';

import {Label, Value, Title, Container, ItemContainer} from './styles';

const CardInfo: Card = ({title, children}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};

const Item: React.FC<ItemProps> = ({label, value}) => (
  <ItemContainer>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </ItemContainer>
);

CardInfo.Item = Item;

export default CardInfo;
