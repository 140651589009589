import React, {useState, useEffect} from 'react';

import {premioService} from 'services';
import {Saldo} from 'models';
import {useTheme} from 'styled-components';

import {Title} from './styles';
import Header from 'components/Header';
import Container from 'components/PageContainer';
import Mobile from './components/SaldoMobile';
import Desktop from './components/SaldoDesktop';

const Creditos: React.FC = () => {
  const {isMobile} = useTheme();
  const [saldo, setSaldo] = useState<Saldo[]>([]);

  useEffect(() => {
    const loadSaldoLiberado = async () => {
      try {
        const response = await premioService.listarSaldoLiberado();
        setSaldo(response);
      } catch (error) {}
    };
    loadSaldoLiberado();
  }, []);
  return (
    <Container>
      <Header hideCreditos />
      <Title>Saldo Liberado</Title>
      {isMobile ? <Mobile creditos={saldo} /> : <Desktop saldo={saldo} />}
    </Container>
  );
};

export default React.memo(Creditos);
