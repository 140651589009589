import React, {useState, forwardRef, useImperativeHandle} from 'react';

import {Options, OpenSnack, CloseSnack} from './types';

import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const SnackAlert = forwardRef((props, ref) => {
  const [options, setOptions] = useState<Options>(defaultOptions);

  const open: OpenSnack = (options) => {
    setOptions((prev) => ({...prev, ...options, open: true}));
  };
  const close: CloseSnack = () => {
    setOptions((prev) => ({...prev, open: false}));

    //Por causa da animação de fechamento
    //temos que aguardar um tempo para restar os props do snack
    setTimeout(() => {
      setOptions(defaultOptions);
    }, 500);
  };

  useImperativeHandle(ref, () => ({
    close,
    open,
  }));
  return (
    <Snackbar
      open={options.open}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: options.vertical || 'bottom',
        horizontal: options.horizontal || 'center',
      }}
      onClose={options.handleClose || close}>
      <MuiAlert
        onClose={options.handleClose || close}
        elevation={6}
        variant="filled"
        severity={options.severity}>
        {options.message}
      </MuiAlert>
    </Snackbar>
  );
});
const defaultOptions: Options = {
  open: false,
  message: '',
  vertical: 'top',
  horizontal: 'right',
  severity: 'success',
  autoHideDuration: 6000,
  handleClose: undefined,
};

export * from './types';
export default SnackAlert;
