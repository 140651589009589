import React, {useContext, useMemo} from 'react';

import {currencyFormat} from 'utils/mask';
import {useTheme} from 'styled-components';
import {SorteioContext} from 'context/SorteioContext';

import Text from 'components/Text';
import {Container, PremioContainer, UltimosPremiosContainer} from './styles';
import {PremioProps} from './types';

const Saldo: React.FC = () => {
  const {fonts, colors} = useTheme();
  const {
    state: {premios},
  } = useContext(SorteioContext);
  return (
    <Container>
      <UltimosPremiosContainer>
        <Text
          size={20}
          font={fonts.poppins_semiBold}
          color={colors.primary_text}>
          Últimos Prêmios
        </Text>
        {premios.map((element, index) => (
          <Premio key={index} premio={element} />
        ))}
      </UltimosPremiosContainer>
    </Container>
  );
};

const Premio: React.FC<PremioProps> = ({premio}) => {
  const {fonts} = useTheme();

  const data = useMemo(() => {
    try {
      return new Date(premio.dtCreate).toLocaleDateString('pt-br');
    } catch (error) {
      return premio.dtCreate;
    }
  }, [premio.dtCreate]);

  return (
    <PremioContainer>
      <Text size={15} font={fonts.poppins_medium}>
        {data}
      </Text>
      <Text font={fonts.poppins_light}>{currencyFormat(premio.valor)} </Text>
    </PremioContainer>
  );
};
export default React.memo(Saldo);
