import React from 'react';

import GuardRoute from 'components/GuardRoute';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';

import App from './App';
import Game from 'pages/App/Game';
import Auth from '../pages/Auth/Login';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';

import Detalhamento from './App/Detalhamento';

import NoMatch from 'pages/NoMatch';
import Sidebar from 'components/Sidebar';

const Routes: React.FC = () => {
  return (
    <Router>
      <Sidebar />
      <Switch>
        <GuardRoute type="private" path="/" exact component={App} />
        <GuardRoute
          type="private"
          path="/detalhamento"
          component={Detalhamento}
        />

        <GuardRoute type="private" path="/Game" exact component={Game} />

        <GuardRoute type="public" path="/auth" component={Auth} />
        <GuardRoute
          type="public"
          path="/forgotPassword"
          component={ForgotPassword}
        />
        <GuardRoute
          type="public"
          path="/resetPassword"
          component={ResetPassword}
        />

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
