import styled from 'styled-components';

import background from 'assets/images/backgroundGame.png';

export const Container = styled.div.attrs({
  className: 'container',
})`
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${({theme}) => theme.colors.primary};
`;

export const InnerContainer = styled.div`
  width: 95%;
  height: 90%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: ${({theme}) => theme.colors.primary_dark};
  background-position: center;
  background-image: url(${background});
`;
