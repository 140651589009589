import React, {useState, forwardRef, useImperativeHandle} from 'react';

import {AnimatedCardProps, AnimatedCardMethods} from './types';

import frontImage from 'assets/images/gerenciarCard_front.png';
import backImage from 'assets/images/gerenciarCard_back.png';

import {Face, FaceBack, CardImage, Container} from './styles';

const AnimatedCard = forwardRef<AnimatedCardMethods, AnimatedCardProps>(
  ({jogadasRestantes, onCardFlip, onError, onClick}, ref) => {
    const [angle, setAngle] = useState(180);
    const [blockRotate, setBlockRotate] = useState<boolean>(false);
    const [imageURL, setImageURL] = useState<string | null>(null);

    const flipCard = () => {
      if (angle === 360) {
        setAngle(angle - 180);
        return;
      }
      setAngle(angle + 180);
    };

    const _onClick = () => {
      if (!blockRotate) {
        onClick();
      }
    };

    const cardFlip = (_imageURL: string) => {
      setImageURL(_imageURL);
      setBlockRotate(true);
      flipCard();
    };

    useImperativeHandle(ref, () => ({
      cardFlip,
    }));
    return (
      <Container onClick={_onClick} angle={angle} time={2}>
        <Face>
          <CardImage src={imageURL || frontImage} />
        </Face>
        <FaceBack>
          <CardImage src={backImage} />
        </FaceBack>
      </Container>
    );
  },
);

export * from './types';
export default React.memo(AnimatedCard);
