import styled from 'styled-components';

import Text from 'components/Text';

export const MiddleContainer = styled.div`
  display: flex;
  margin: 1% 0px;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({theme}) => theme.colors.primary};

  @media screen and (max-width: 480px) {
    flex-direction: column;
    height: 50%;
    justify-content: flex-start;
  }
`;
export const Group = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
export const GroupText = styled(Text)`
  width: 80%;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;
export const Divisor = styled.div`
  height: 50vh;
  width: 1px;
  background-color: ${({theme}) => theme.colors.secundary};

  @media screen and (max-width: 480px) {
    height: 1px;
    width: 90%;
    align-self: center;
  } ;
`;

export const CardsContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const Card = styled.img`
  width: 70px;
  height: auto;
  @media screen and (max-width: 480px) {
    width: 30px;
  }
`;
