import React from 'react';

import {CardsModalProps} from './types';
import {useTheme} from 'styled-components';

import {
  Card,
  Group,
  Divisor,
  GroupText,
  CardsContainer,
  MiddleContainer,
} from './styles';

import cardFront from 'assets/images/gerenciarCard_amostra.png';
import cardBack from 'assets/images/gerenciarCard_back.png';

const Middle: React.FC = () => {
  const {fonts} = useTheme();

  return (
    <MiddleContainer>
      <Group>
        <GroupText font={fonts.poppins_regular} size={20}>
          Após clicar em jogar as cartas serão distribuídas na sua tela
        </GroupText>
        <Cards showFront={false} />
      </Group>
      <Divisor />
      <Group>
        <GroupText font={fonts.poppins_regular} size={20}>
          Algumas cartas contém prêmios,escolha uma para virar e cruze os dedos
        </GroupText>
        <Cards showFront />
      </Group>
    </MiddleContainer>
  );
};

const Cards: React.FC<CardsModalProps> = ({showFront}) => (
  <CardsContainer>
    <Card src={cardBack} />
    <Card src={showFront ? cardFront : cardBack} />
    <Card src={cardBack} />
    <Card src={cardBack} />
  </CardsContainer>
);

export default React.memo(Middle);
