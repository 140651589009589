import {TextProps} from './types';
import React from 'react';

import {TextBase} from './styles';

const Text: React.FC<TextProps> = (props) => {
  return <TextBase {...props}>{props?.children}</TextBase>;
};

export default React.memo(Text);
