import React from 'react';

import {Container, InnerContainer} from './styles';

const PageContainer: React.FC = ({children}) => {
  return (
    <Container>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
};

export default PageContainer;
