import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container',
})`
  align-items: center;
  justify-content: center;
`;

export const InnerContainer = styled.div`
  width: 90%;
  height: 90%;
  padding: 20px;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({theme}) => theme.colors.primary};

  @media screen and (max-width: 480px) {
    width: 80%;
    height: 85%;
  }
`;
