import React from 'react';

import {HomeFooterProps} from './types';
import {useTheme} from 'styled-components';

import Creditos from '../Creditos';
import {AiFillPlayCircle} from 'react-icons/ai';
import {Container, PlayButton, PlayButtonText} from './styles';

const Footer: React.FC<HomeFooterProps> = ({onPlay}) => {
  const {colors, isMobile} = useTheme();
  return (
    <Container>
      {isMobile && (
        <>
          <Creditos />
          <PlayButton onClick={onPlay} onCopy={alert}>
            <AiFillPlayCircle color={colors.secundary} />
            <PlayButtonText>JOGAR</PlayButtonText>
          </PlayButton>
        </>
      )}
    </Container>
  );
};

export default React.memo(Footer);
