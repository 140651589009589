import React, {
  useMemo,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  createContext,
} from 'react';

import {Context, Reducer, SorteioTypes, ContextActions} from './types';
import {UserContext} from 'context/UserContext';
import {premioService} from 'services';
import {SaldoDashboard, Sorteio, SorteioVigente} from 'models';

export const SorteioContext = createContext<Context>({} as Context);

const SorteioProvider: React.FC = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    state: {user},
  } = useContext(UserContext);
  const buscarSorteio = useCallback(async () => {
    try {
      const response = await premioService.getSorteio();
      return response;
    } catch (error) {
      return {sorteioVigente: {}, creditos: 0} as Sorteio;
    }
  }, []);

  const buscarPremios = useCallback(async () => {
    try {
      const response = await premioService.getPremio();
      return response;
    } catch (error) {
      return [];
    }
  }, []);

  const buscarSaldoUsuario = useCallback(async () => {
    try {
      const response = await premioService.getSaldoUsuario();
      return response;
    } catch (error) {
      return {saldoBloqueado: 0, saldoLiberado: 0};
    }
  }, []);

  const atualizarSorteio = useCallback(async () => {
    const [sorteio, premios, saldo] = await Promise.all([
      buscarSorteio(),
      buscarPremios(),
      buscarSaldoUsuario(),
    ]);

    dispatch({
      type: SorteioTypes.SetSorteio,
      saldo,
      premios,
      creditos: sorteio.creditos,
      sorteioVigente: sorteio.sorteioVigente,
    });
  }, [buscarPremios, buscarSorteio, buscarSaldoUsuario]);

  useEffect(() => {
    const load = async () => {
      atualizarSorteio();
    };
    load();
  }, [user.userName, atualizarSorteio]);

  const actions: ContextActions = useMemo(
    () => ({
      atualizarSorteio: atualizarSorteio,
      disableHowToPlay: () => {
        dispatch({type: SorteioTypes.SetShowHowToPlay});
      },
    }),
    [atualizarSorteio],
  );

  return (
    <SorteioContext.Provider value={{state, actions}}>
      {children}
    </SorteioContext.Provider>
  );
};
const initialState = {
  creditos: 0,
  premios: [],
  saldo: {} as SaldoDashboard,
  showHowToPlay: true,
  sorteioVigente: {} as SorteioVigente,
};

const reducer: Reducer = (prevState, action) => {
  switch (action.type) {
    case SorteioTypes.SetSorteio:
      return {
        ...prevState,
        saldo: action.saldo,
        premios: action.premios,
        creditos: action.creditos,
        sorteioVigente: action.sorteioVigente,
      };
    case SorteioTypes.SetShowHowToPlay:
      return {...prevState, showHowToPlay: false};
  }
};
export default SorteioProvider;
