import React, {useState, useEffect} from 'react';

import {ExtratoPagamento} from 'models';
import {ExtratoPagamentoDesktopProps} from './types';
import {DataGrid, GridColDef} from '@mui/x-data-grid';

import {Container, DataGridContainer} from './styles';

const ExtratoPagamentoDesktop: React.FC<ExtratoPagamentoDesktopProps> = ({
  extratoPagamento: _extratoPagamento,
}) => {
  const [extratoPagamento, setExtratoPagamento] = useState<ExtratoPagamento[]>(
    [],
  );

  useEffect(() => {
    const temp = _extratoPagamento.map((e, index) => ({
      ...e,
      id: index,
      valor: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(e.valor as number),
    }));
    setExtratoPagamento(temp);
  }, [_extratoPagamento]);

  return (
    <Container>
      <DataGridContainer>
        <DataGrid rows={extratoPagamento} columns={columns} />
      </DataGridContainer>
    </Container>
  );
};

const columns: GridColDef[] = [
  {field: 'referencia', headerName: 'Competência', width: 350},
  {field: 'valor', headerName: 'Valor', width: 350},
];

export default React.memo(ExtratoPagamentoDesktop);
