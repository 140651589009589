import styled from 'styled-components';

import Text from 'components/Text';
import MaterialDialog from '@material-ui/core/Dialog';
import MaterialDialogTitle from '@material-ui/core/DialogTitle';

import _Button from '@material-ui/core/Button';

export const DialogContainer = styled(MaterialDialog)``;
export const Container = styled.div`
  overflow: hidden;
  border-radius: 10px;
  background-color: ${({theme}) => theme.colors.primary};
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  color: ${({theme}) => theme.colors.primary_text};
`;

export const DialogContentText = styled(Text)`
  font-size: 18px;
  color: ${({theme}) => theme.colors.tertiary_text};
`;
export const Button = styled(_Button)`
  box-shadow: none;
`;

export const ButtonText = styled(Text)`
  color: ${({theme}) => theme.colors.yellow};
`;
