import React, {useState, useEffect, useContext, useCallback} from 'react';

import {Menu, SidebarProps} from './types';
import {AuthContext} from 'context/AuthContext';
import {SidebarContext} from 'context/SidebarContext';
import {useHistory, useLocation} from 'react-router';

import {AiOutlineHome} from 'react-icons/ai';
import {BiLogOutCircle} from 'react-icons/bi';
import {RiCoinsFill, RiBillLine} from 'react-icons/ri';
import {MdAttachMoney, MdMoneyOff} from 'react-icons/md';

import logo from 'assets/images/icon.png';

import SidebarItem from './components/SidebarItem';
import {
  Bar,
  Container,
  BarFooter,
  BlackArea,
  BarContent,
  ImageHeader,
  ImageHeaderContainer,
  TRANSITION_DURATION,
} from './styles';

const Sidebar: React.FC<SidebarProps> = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    state: {isVisible},
    actions: {closeSidebar},
  } = useContext(SidebarContext);
  const {actions: authActions} = useContext(AuthContext);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isVisibleBar, setIsVisibleBar] = useState<boolean>(false);

  const handleItem = useCallback(
    (route: string) => {
      if (route === 'logout') {
        authActions.signOut();
        closeSidebar();
        return;
      }
      history.push(route);
      closeSidebar();
    },
    [closeSidebar, history, authActions],
  );

  useEffect(() => {
    if (isVisible) {
      setIsActive(true);

      setTimeout(() => {
        setIsVisibleBar(true);
      }, 200);
    } else {
      setIsVisibleBar(false);
      setTimeout(() => {
        setIsActive(false);
      }, TRANSITION_DURATION * 1000);
    }
  }, [isVisible]);

  return (
    <>
      {isActive && (
        <Container>
          <BlackArea onClick={closeSidebar} />
          <Bar isVisible={isVisibleBar}>
            <BarContent>
              <ImageHeaderContainer>
                <ImageHeader src={logo} />
              </ImageHeaderContainer>
              {menus.map(
                (element) => (
                  <SidebarItem
                    menu={element}
                    key={element.route}
                    onItemClick={handleItem}
                    activeRoute={location.pathname}
                  />
                ),
                [],
              )}
            </BarContent>
            <BarFooter>
              <SidebarItem
                onItemClick={handleItem}
                activeRoute={location.pathname}
                menu={{Icon: BiLogOutCircle, name: 'Sair', route: 'logout'}}
              />
            </BarFooter>
          </Bar>
        </Container>
      )}
    </>
  );
};

const menus: Menu[] = [
  {
    name: 'Home',
    route: '/',
    Icon: AiOutlineHome,
  },
  {
    name: 'Extrato',
    route: '/detalhamento/extratopagamento',
    Icon: RiBillLine,
  },
  {
    name: 'Creditos',
    route: '/detalhamento/creditos',
    Icon: RiCoinsFill,
  },
  {
    name: 'Saldo bloqueado',
    route: '/detalhamento/saldobloqueado',
    Icon: MdMoneyOff,
  },
  {
    name: 'Saldo liberado',
    route: '/detalhamento/saldoliberado',
    Icon: MdAttachMoney,
  },
];

export default React.memo(Sidebar);
