import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';

import {premioService} from 'services';
import {ResultadoSorteio} from 'models';
import {currencyFormat} from 'utils/mask';
import {useHistory} from 'react-router-dom';
import {SorteioContext} from 'context/SorteioContext';

import Dialog from 'components/Dialog';
import Credito from './components/Credito';
import SnackAlert, {SnackAlertMethods} from 'components/SnackAlert';
import HowToPlay, {HowToPlayMethods} from 'components/HowToPlay';
import AnimatedCard, {AnimatedCardMethods} from 'components/AnimatedCard';

import {Container, InnerContainer} from './styles';

const Game: React.FC = () => {
  const history = useHistory();
  const snackRef = useRef<SnackAlertMethods>({} as SnackAlertMethods);
  const howRef = useRef<HowToPlayMethods>({} as HowToPlayMethods);

  const {
    state: {sorteioVigente, showHowToPlay},
    actions: sorteioActions,
  } = useContext(SorteioContext);

  const cartasRef = useRef<AnimatedCardMethods[]>([]);
  const [cartas] = useState(() =>
    createArray(sorteioVigente.qtdAcaoPorCredito + 2),
  );

  const [dialog, setDialog] = useState(initialState.dialog);
  const [premios, setPremios] = useState<ResultadoSorteio[]>([]);
  const [jogadasRestantes, setJogadasRestantes] = useState(
    sorteioVigente.acoesRestantes,
  );

  const closeModal = useCallback(
    (playGame) => {
      howRef.current.close();
      if (playGame) {
        sorteioActions.disableHowToPlay();
        return;
      }
      history.goBack();
    },
    [history, sorteioActions],
  );

  const onCardFlip = useCallback((premio: ResultadoSorteio) => {
    setJogadasRestantes((prev) => prev - 1);
    setPremios((prev) => [...prev, premio]);
  }, []);

  const onError = useCallback((message) => {
    snackRef.current.open({message, severity: 'error'});
  }, []);

  const handleCloseDialog = useCallback(
    (action) => {
      setDialog((prev) => ({
        ...prev,
        isOpen: false,
      }));
      if (action === 'agree') {
      } else if (action === 'disagree') {
        history.goBack();
      }
    },
    [history],
  );
  const onPressCard = async (cartaIndex: number) => {
    try {
      const carta = cartasRef.current[cartaIndex];
      if (carta && jogadasRestantes > 0) {
        const response = await premioService.sortear();
        setJogadasRestantes((prev) => prev - 1);
        setPremios((prev) => [...prev, response]);

        let img = new Image();
        img.src = response.imagem;
        img.onload = () => {
          carta.cardFlip(response.imagem);
        };
      }
    } catch (error: any) {
      onError(error?.message || 'Tivemos um problema, tente novamente');
    }
  };

  useEffect(() => {
    if (howRef.current && showHowToPlay) {
      howRef.current.open();
    }
  }, [showHowToPlay]);

  useEffect(() => {
    if (cartasRef.current.length > 0) {
      sorteioVigente.premiosSorteados?.forEach((element, index) => {
        cartasRef.current[index].cardFlip(element.imagem);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jogadasRestantes === 0) {
      sorteioActions.atualizarSorteio();
      const calculaPremio = async () => {
        try {
          const valorPremio = await premioService.getResultadoSorteio();
          setTimeout(() => {
            setDialog((prev) => ({
              ...prev,
              isOpen: true,
              dialogContent: `Parabens você ganhou ${currencyFormat(
                valorPremio,
              )}`,
            }));
          }, 1500);
        } catch (error) {}
      };
      calculaPremio();
    }
  }, [premios, jogadasRestantes, sorteioActions]);
  return (
    <Container>
      <HowToPlay ref={howRef} closeModal={closeModal} />
      <SnackAlert ref={snackRef} />
      <Credito />
      <InnerContainer>
        {cartas.map((element, index) => (
          <AnimatedCard
            onError={onError}
            onCardFlip={onCardFlip}
            onClick={() => onPressCard(index)}
            jogadasRestantes={jogadasRestantes}
            ref={(ref: AnimatedCardMethods) => (cartasRef.current[index] = ref)}
          />
        ))}
        <Dialog
          open={dialog.isOpen}
          dialogTitle={dialog.dialogTitle}
          dialogContent={dialog.dialogContent}
          disagreeButton={dialog.disagreeButton}
          handleClose={handleCloseDialog}
        />
      </InnerContainer>
    </Container>
  );
};
const initialState = {
  warningBanner: {
    isOpen: false,
    message: '',
    color: 'success' as any,
  },
  dialog: {
    isOpen: false,
    dialogContent: '',
    dialogTitle: 'Parabéns',
    agreeButton: 'Jogar novamente',
    disagreeButton: 'Voltar para o início',
  },
};
export default Game;

const createArray = (length: number = 3) => {
  if (Number.isNaN(length)) {
    return Array.from(Array(3));
  }
  return Array.from(Array(length));
};
