import styled from 'styled-components';

import Text from 'components/Text';

export const Container = styled.div`
  display: flex;
  width: 100%;
  border: solid ${({theme}) => theme.colors.secundary};
  border-width: 0px 0px 1px;
  flex-direction: column;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
`;
export const IconContainer = styled.div`
  width: 3vw;
`;
export const TextInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: ${({theme}) => theme.colors.secundary};
  font-family: ${({theme}) => theme.fonts.poppins_medium};
`;

export const Placeholder = styled(Text)`
  font-size: 20px;
  font-family: ${({theme}) => theme.fonts.poppins_light};

  @media screen and (max-width: 480px) {
    font-size: 16px;
    margin-top: 10px;
  }
`;
