import React, {useState, useEffect} from 'react';

import {premioService} from 'services';
import {CreditoDetalhado} from 'models';
import {useTheme} from 'styled-components';

import {Title} from './styles';
import Header from 'components/Header';
import Container from 'components/PageContainer';
import Mobile from './components/CreditosMobile';
import Desktop from './components/CreditosDesktop';

const Creditos: React.FC = () => {
  const {isMobile} = useTheme();
  const [creditos, setCreditos] = useState<CreditoDetalhado[]>([]);

  useEffect(() => {
    const loadCreditos = async () => {
      try {
        const response = await premioService.listarCreditosDetalhados();
        setCreditos(response);
      } catch (error) {}
    };
    loadCreditos();
  }, []);
  return (
    <Container>
      <Header hideCreditos />
      <Title>{isMobile ? 'Créditos' : 'Detalhamento dos créditos'}</Title>
      {isMobile ? (
        <Mobile creditos={creditos} />
      ) : (
        <Desktop creditos={creditos} />
      )}
    </Container>
  );
};

export default React.memo(Creditos);
